import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { delay } from 'utils';
import SpinnerIcon from './icons/SpinnerBlack';

type Props = {
  readonly skipCategoryId?: number;
};

const Loading = () => (
  <div className="pc-row">
    <SpinnerIcon size={70} />
  </div>
);

// @ts-ignore
const AsyncCatalogContent = React.lazy(() => delay(3000).then(() => import('./AsyncCatalogContent')));

// примерная высота CategoryRow для формирования placeholder нужной высоты
// иначе при обновлении страницы браузер бросает в начало, не сохраняя позицию скрола
const CATEGORY_ROW_HEIGHT = 370;

export const AsyncCatalog = ({ skipCategoryId }: Props) => {
  const data = useStaticQuery<GatsbyTypes.AsyncCatalogQuery>(
    graphql`
      query AsyncCatalog {
        api {
          categories {
            rowId
          }
        }
      }
    `,
  );

  const wrapStyle = {
    minHeight: data.api.categories.length * CATEGORY_ROW_HEIGHT,
  };

  if (typeof window === 'undefined') {
    // is ssr
    return <div style={wrapStyle} />;
  }

  return (
    <div style={wrapStyle}>
      <React.Suspense fallback={<Loading />}>
        <AsyncCatalogContent skipCategoryId={skipCategoryId} />
      </React.Suspense>
    </div>
  );
};
