import * as React from 'react';
import { Layout } from 'components/Layout';
import { SEO } from 'components/SEO';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { AsyncCatalog } from 'components/AsyncCatalog';
import img from 'images/404.png';
import retinaImg from 'images/404@2x.png';

const NotFoundPage = () => (
  <Layout>
    <SEO title="Не удалось найти эту страницу" />
    <div className="pc-row">
      <Breadcrumbs />
      <div className="flex items-center text-black">
        <img src={img} srcSet={`${retinaImg} 2x`} alt="" className="mr-8" />
        <div>
          <h1 className="text-5xl font-bold leading-none mb-8">Не удалось найти эту страницу.</h1>
          <div className="text-xl leading-6">
            Почему бы не ознакомиться с нашими категориями и самыми популярными продуктами?
          </div>
        </div>
      </div>
    </div>
    <div className="border-b border-gray-90 my-16" />
    <AsyncCatalog />
  </Layout>
);

export default NotFoundPage;
